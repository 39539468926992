
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '../../services/authentication.service';
  import {ImageTransferService} from '../../services/image-transfer.service';
  import EditParentDialog from '@/components/EditParentDialog.component.vue';
  import { TeacherEditionDto } from '@/interfaces/teacherDtos/TeacherEditionDto';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';
  import { ParentDto } from '@/interfaces/parents/ParentDto';
import { ParentEditionDto } from '@/interfaces/parents/ParentEditionDto';


  const editTeacherType = 'edit';
  const createTeacherType = 'create';

  /**
   * @desc page de gestion des enseignants
   */
  @Component({
    components: {
      'edit-parent-dialog' : EditParentDialog,
    },
  })
  export default class ParentsAdmin extends Vue 
  {
    private tableHeaders = [
      { text: '#', sortable: false },
      { text: 'Login', value: 'login', sortable: true},
      { text: 'Actions', sortable: false },
      { text: '', sortable: false },
    ];

    private allParents : ParentDto[] = [];
    selectedParent : ParentDto = new ParentDto({});
    parentEdition = false;

    editParentType = 'edit';

    private loading= false;
    private tableSortBy = '';
    private tableDesc = true;


    /**
     * @desc initialisation et écoute des événements émis
     */
    mounted(): void {
      if(AuthenticationService.getUserStatus() !== Consts.user_status_admin){
        this.$router.push('/');
        return;
      }
      this.loadParents();
    }

    /**
     * @desc charge les enseignants contenues en base de données
     */
    private loadParents() : void { 
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getAllParents, headers)
        .then((response) => {
          this.allParents = response.data;
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc appelle la boite de dialogue de création d'un enseignant
     */
    private addParent() : void {
      (this.$refs.editParentDialog as EditParentDialog).show(new ParentDto({}), createTeacherType);
    }

    serverDialog = false;

    /**
     * @desc requète de création d'un enseignant envoyée au serveur
     */
    private async serverCreateParent(parent : ParentEditionDto) : Promise<void> {
      if(this.serverDialog) return;
      this.serverDialog = true;
      
      const headers = AuthenticationService.getRequestHeader();
       axios
        .post(  UrlConsts.createParent, parent, headers)
        .then((response) => {
          this.serverDialog = false;
          (this.$refs.editParentDialog as EditParentDialog).hide();
          const createdParent = new ParentDto(response.data);
          this.allParents.splice(0, 0, createdParent);
        })
        .catch(error => this.serverError(error))
    }


    /**
     * @desc ouvre la boite de dialogue d'édition d'un enseignant
     */
    private editParent(parent : ParentDto) : void {
      if(!parent) return;
      this.selectedParent = parent;
      this.parentEdition = true;
      (this.$refs.editParentDialog as EditParentDialog).show(parent, editTeacherType);
    }

    /**
     * @desc requète de modification d'un enseignant envoyée au serveur
     */
    private async serverEditParent(parent : ParentEditionDto) : Promise<void> {
      if(this.serverDialog) return;
      this.serverDialog = true;

      const headers = AuthenticationService.getRequestHeader();
       axios
        .post(UrlConsts.editParent, parent, headers)
        .then((response) => {
          this.serverDialog = false;
          (this.$refs.editParentDialog as EditParentDialog).hide();
          const editedParent = new ParentDto(response.data);
          this.updateParent(editedParent);
        })
        .catch(error => this.serverError(error))
    }

    /**
     * @desc met à jour l'affichage d'un enseignant dans la table
     */
    private updateParent(data : ParentDto) : void {
      const teacherIndex = this.allParents.findIndex(x => x.id === data.id);
        if(teacherIndex > -1) {
          this.allParents[teacherIndex] = data;
          this.allParents = this.allParents.splice(0);
        }
    }

    /**
     * @desc affiche une erreur serveur
     */
    private serverError(error : string) : void{
      console.log(error);
      this.serverDialog = false;
    }
  }
