
  import { Component, Vue } from 'vue-property-decorator';
  import { TeacherDto } from '@/interfaces/teacherDtos/TeacherDto';
  import { ParentEditionDto } from '@/interfaces/parents/ParentEditionDto';
  import { ParentDto } from '@/interfaces/parents/ParentDto';

  // types utilisés pour définir le comportement du composant
  const editType = "edit";
  const createType = 'create';

  /**
   * @desc boite de dialogue permettant de créer/modifier un ensignant
   */
  @Component({
    components: {

    },
  })
  export default class EditParentDialog extends Vue {
    boxWidth = "60rem";

    visible = false;
    parent: ParentDto = new ParentDto({});
    type = 'create'; // default value -> create

    nameInput = "";
    firstnameInput = "";
    loginInput = "";
    passwordInput = "";

    /**
     * @desc montre la boite de dialogue de edition/creation d'un enseignant
     * @param teacher : object enseignant initial
     * @param type : définit le comportement du composant, soit 'edit' soit 'create'
     */
    public show(parent : ParentDto, type : string) : void {
      this.parent = parent;

      this.loginInput = parent.login || "";
      this.passwordInput = "";

      this.type = type;
      this.visible= true;
      this.$vuetify.theme.dark = false;
    }

    /**
     * @desc cache la boite de dialogue
     */
    public hide() : void {
      this.visible = false;
    }

    /**
     * @desc fonction qui valide les données rentrées, et les renvoie en emettant un événement associé
     */
    public validate() : void{
      (this.$refs.inputForm as any).validate();

      setTimeout(() => {
        if(!this.visible) return;
        let editedParent = this.getUpdatedData();
        if(this.type === editType) {
          if(Object.keys(editedParent).length <= 1){ // seulement l'id
            this.cancel();
          }
          else this.$emit('validated', editedParent);
        }
        else{
          this.$emit('created', editedParent);
        }
      }, 200);
    }

    /**
     * @desc renvoie un object contenant toutes les données renseignées par l'utilisateur
     */
    private getUpdatedData() : ParentEditionDto {
      let outputParent = new ParentEditionDto({});

      if(this.type === 'edit') { // mode édition
        outputParent.id = this.parent.id;
        outputParent.login = this.parent.login !== this.loginInput ? this.loginInput : undefined;
        outputParent.password = this.passwordInput ? this.passwordInput : undefined;
      }
      else { // mode création
        outputParent.login = this.loginInput;
        outputParent.password = this.passwordInput;
      }
   
      return outputParent;
    }

    /**
     * @desc annule les modifications apportées. Cache simplement la boite de dialogue
     */
    public cancel() : void {
      setTimeout(() => {
        this.visible = false;
      }, 200);
    }
  }

